/* App.css */
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.posts {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  font-size: 3rem;
  color: #000;
}

/* Home.css */
.custom-card {
    background-color: #f8f9fa;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    width: calc(33.33% - 20px); /* Largura fixa com ajuste para margens */
    overflow: hidden;
    transition: transform 0.2s;
    margin-bottom: 20px;
    max-height: 250px;
  }

  .card-title {
    font-size: 1.5rem;
    margin-bottom: 5px; /* Reduza o espaçamento abaixo do título */
    overflow: hidden; /* Evita que o texto do título empurre o conteúdo abaixo do cartão */
    text-overflow: ellipsis; /* Adiciona reticências para indicar que o texto foi cortado */
    white-space: nowrap; /* Impede que o texto seja quebrado em várias linhas */
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Distribui equidistantemente e alinha ao começo/final */
    margin: 0 20px; /* Adicione margem para afastar das margens laterais */
  }

  .list-group-item {
    background-color: transparent;
    border: none;
    font-size: 0.8rem; /* Reduza o tamanho do texto do item da lista */
  }

  .card-text {
    font-size: 0.8rem; /* Tamanho menor para a categoria */
    color: #6c757d; /* Cor de texto sutil */
  }

  .text-success {
    color: green;
  }
  
  .text-danger {
    color: red;
  }

  .portal-buttons {
    display: flex;
    margin-bottom: 20px;
  }
  
  .portal-buttons button {
    border: none;
    background-color: transparent;
    color: #007bff;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .portal-buttons button.active {
    background-color: #007bff;
    color: white;
  }

  .posts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .portal-buttons {
    display: flex;
    margin-top: 20px;
  }
  
  .portal-button {
    border: none;
    background-color: transparent;
    color: #007bff;
    cursor: pointer;
    margin-right: 8px; /* Reduzir a margem para espaçamento menor entre os botões */
    padding: 6px 10px; /* Reduzir o padding para botões menores */
    border-radius: 15px; /* Reduzir o valor para bordas mais arredondadas */
    font-size: 14px; /* Reduzir o tamanho do texto */
    transition: background-color 0.3s, color 0.3s;
  }
  
  .portal-button.active {
    background-color: #007bff;
    color: white;
  }
  
  .loading-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    font-size: 18px;
  }

  .load-more-button {
    margin: 20px auto;
    display: block;
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .load-more-button:hover {
    background-color: #0056b3;
  }

    .loading-animation-container {
      display: flex;
      justify-content: center; /* Centraliza horizontalmente */
      align-items: center; /* Centraliza verticalmente */
      height: 0vh; /* Ocupa toda a altura da viewport */
    }
    
    .loading-animation {
      display: inline-block;
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 2s linear infinite;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }


/* Em dispositivos com largura menor que 576px */
@media (max-width: 575px) {
  .posts {
    padding: 0px;
  }

  .portal-buttons {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .portal-button {
    margin: 5px 0;
    width: 100%;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 10px;
  }

  .custom-card {
    width: 100%; /* Largura da tela com espaçamento de 10px entre as laterais */
    max-width: 100%; /* Largura máxima ajustada para a tela */
    margin: 0 auto; /* Centraliza o card na tela */
    box-sizing: border-box; /* Inclui padding e borda na largura */
    padding: 10px; /* Espaçamento interno do card */
  }

  /* Reduz o tamanho da fonte dos textos no card */
  .custom-card .card-title,
  .custom-card .card-text,
  .custom-card .text-success,
  .custom-card .text-danger {
    font-size: 0.75rem; /* Ajuste o tamanho da fonte conforme necessário */
  }

  /* Ajusta o link para quebrar em várias linhas se necessário */
  .custom-card .card-text a {
    word-break: break-all;
  }
  
  /* Permite que o título do card tenha quebra de linha automática */
  .custom-card .card-title {
    white-space: normal;
  }
}

/* Estilos para o cabeçalho */
header {
  background-color: #333; /* Cor de fundo escura */
  padding: 20px 0;
  text-align: center;
  color: #fff; /* Texto branco */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra leve para dar profundidade */
}

/* Estilos para os textos centralizados */
.status-container {
  margin-top: 1em;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5rem; /* Tamanho de fonte maior para o título */
  font-weight: bold; /* Negrito para o título */
}

.texto-resultado {
  font-weight: 100!important;
}
.verificando-disponibilidade {
  font-size: 1rem;
}






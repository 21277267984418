/* LoginPage.css */
.login-container {
    margin-top: 50px!Important;
    max-width: 400px;
    margin: 0 auto;
    padding: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
  
  .login-title {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .login-login {
    font-size: 18px;
    margin-top: 10px;
  }
  
  .login-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
  }
  
  .login-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
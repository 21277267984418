.portais {
  margin: 20px; /* Adicione a margem desejada */
}

.info-header {
  text-align: center; /* Centraliza o texto */
  margin-bottom: 20px; /* Adiciona espaço abaixo do título */
}

.filters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px; /* Adiciona espaço abaixo dos filtros */
}

.filters label {
  margin-right: 10px; /* Adicione espaçamento à direita dos rótulos */
}

.filters select,
.filters input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px; /* Adicione espaçamento à direita dos campos */
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 20px;
}

.portal-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -10px;
}

.portal-card {
  flex: 0 0 calc(33.33% - 20px);
  margin: 10px;
  display: flex;
}

.portal-card .card {
  width: 100%;
}

@media (max-width: 991px) {
  .portal-card {
    flex: 0 0 calc(50% - 20px);
  }
}

@media (max-width: 767px) {
  .portal-card {
    flex: 0 0 calc(100% - 20px);
  }
}

.loading-animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

.loading-animation {
  width: 30px;
  height: 30px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.date {
  margin-left: 3px; /* Adiciona margem entre o texto e a data */
}

.recent-post-date {
  color: green;
}

.old-post-date {
  color: red;
}

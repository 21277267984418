/* Navbar CSS */

.navbar {
    background-color: #2c3e50;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.navbar .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    color: #ecf0f1;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.navbar .menu-bars:hover {
    color: #3498db;
}

.nav-menu {
    background-color: #ecf0f1;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms ease-in-out;
    z-index: 999;
    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.2);
}

.nav-menu.active {
    left: 0;
    transition: 450ms ease-in-out;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #2c3e50;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.nav-text a:hover {
    background-color: #3498db;
    color: #fff;
    transform: scale(1.05);
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #ecf0f1;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
}

.nav-menu .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    color: #2c3e50;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.nav-menu .menu-bars:hover {
    color: #3498db;
}

span {
    margin-left: 16px;
}

/* Estilos para o overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
}

.overlay.active {
    opacity: 1;
    visibility: visible;
}

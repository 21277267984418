/* Calendario.css */

.posts-list {
  max-height: 180px;
  overflow-y: auto;
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.post-title {
  font-size: 15px;
  margin: 8px 0;
  display: flex;
  align-items: flex-start; /* Alteração aqui */
}

.bullet {
  font-size: 15px;
  margin-right: 8px;
  color: #007bff;
}

.date-picker-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.date-picker {
  width: 300px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
}

.custom-calendar {
  width: 100%;
  max-width: 360px;
  border: none;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
}

.react-datepicker__header {
  background-color: #007bff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 15px 8px 8px 8px;
  text-align: center;
  color: #fff;
  font-size: 18px;
}

.react-datepicker__current-month {
  font-weight: bold;
  font-size: 18px;
  color: #fff;
}

.react-datepicker__day {
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.react-datepicker__day-names {
  margin: 0;
  padding: 8px 0;
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day-name {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}

.react-datepicker__week {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #0056b3;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  margin: auto;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  top: 11%;
  transform: translateY(-50%);
}

.react-datepicker__navigation--previous {
  left: 10px;
}

.react-datepicker__navigation--next {
  right: 10px;
}

.custom-card {
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-card:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
}

.loading-animation-container.center-horizontal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

@media (max-width: 576px) {
  .date-picker-container {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .date-picker {
    width: 90%;
    max-width: 90%;
  }

  .custom-calendar {
    max-width: 100%;
  }

  .card-container {
    display: block;
  }

  .custom-card {
    margin-bottom: 20px;
    width: 100%;
  }

  .div-botao-pdf {
    display: flex;
    justify-content: center!important;
     margin-top: 10px!important;
     margin-bottom: 10px!important;
     margin-right: 0!important;
  }
  
}

.pdf-button {
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}